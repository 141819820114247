import React, { lazy } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

const Home = lazy(() => import('./pages'));
const Sobre = lazy(() => import('./SubPages'));

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/sobre" exact component={Sobre} />
        <Redirect exact from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;
