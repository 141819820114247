import { createGlobalStyle } from 'styled-components';
import * as c from './constants';
import fonts from '../assets/fonts/fonts';

export default createGlobalStyle`
  ${fonts};

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
    vertical-align: baseline;
  }

  *:focus {
    outline: none;
  }

  html {
    font-size: 62.5%; // 10px => 1rem
    height: 100%;
  }

  body {
    -webkit-font-smoothing: antialiased !important;
  }

  body {
    font-family: 'Gilroy', sans-serif;
    font-size: ${c.fontSize.default};
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  ol, ul {
	  list-style: none;
  }

  button {
    border: 0;
    background: none;
    font-size: ${c.fontSize.sm};
    cursor: pointer;
  }

  &::selection {
    background: ${c.colors.yellow};
  }

  ::-webkit-scrollbar {
    min-height: 30px;
    width: 13px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #5a5a5a;
    padding: 2px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${c.colors.dark};
  }
`;
