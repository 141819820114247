import React, { useState, useEffect, createContext, useContext } from 'react';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import {
  ANIMATION_LEFT_TO_RIGHT,
  ANIMATION_TOP_TO_BOTTOM,
  ANIMATION_RIGHT_TO_LEFT,
} from '../../constants/animations';

const AnimatedContext = createContext();

function AnimatedProvider({ children }) {
  const [isOpen, setOpen] = useState(false);

  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.25,
  });

  function handleClick() {
    setOpen(!isOpen);
  }

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <AnimatedContext.Provider
      value={{
        isOpen,
        setOpen,
        ref,
        controls,
        handleClick,
        ANIMATION_LEFT_TO_RIGHT,
        ANIMATION_TOP_TO_BOTTOM,
        ANIMATION_RIGHT_TO_LEFT,
      }}
    >
      {children}
    </AnimatedContext.Provider>
  );
}

function useAnimated() {
  const context = useContext(AnimatedContext);

  if (!context) {
    throw new Error('useAnimated must be used within an AnimatedProvider');
  }

  return context;
}

export { AnimatedProvider, useAnimated };
