export const ANIMATION_LEFT_TO_RIGHT = {
  hidden: { x: -1000, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 1,
    },
  },
};

export const ANIMATION_TOP_TO_BOTTOM = {
  hidden: { y: -300, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 1,
    },
  },
};

export const ANIMATION_RIGHT_TO_LEFT = {
  hidden: { x: 1000, opacity: 0 },
  visible: {
    x: 700,
    opacity: 1,
    transition: {
      ease: 'easeOut',
      duration: 1,
    },
  },
};
