import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';

import history from './services/history';
import Routes from './routes';

import GlobalStyle from './styles/global';
import { AnimatedProvider } from './context/animated';
// import { AnimatedProvider } from 'src/context/animated';

import Loading, { Spinner } from './components/Loading';

function App() {
  return (
    <Suspense
      fallback={
        <Loading>
          <Spinner />
        </Loading>
      }
    >
      <Router history={history}>
        <AnimatedProvider>
          <Routes />
          <GlobalStyle />
        </AnimatedProvider>
      </Router>
    </Suspense>
  );
}

export default App;
