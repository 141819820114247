import styled, { keyframes } from 'styled-components';
import * as c from '../../styles/constants';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 8px solid rgba(0, 0, 0, 0.3);
  border-left-color: ${c.colors.yellow};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Loading = styled.div`
  height: 100vh;
  width: 100vw;

  margin: 0;
  padding: 0;

  background: ${c.colors.dark};

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Loading;
