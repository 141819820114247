import { css } from 'styled-components';

import GilroyLight from './Gilroy-Light.woff';
import GilroySemiBold from './Gilroy-Semibold.woff';
import GilroyExtraBold from './Gilroy-Extrabold.woff';

export default css`
  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), local('GilroyLight'),
      url(${GilroyLight}) format('woff');
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), local('GilroySemiBold'),
      url(${GilroySemiBold}) format('woff');
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Gilroy';
    src: local('Gilroy'), local('GilroyExtraBold'),
      url(${GilroyExtraBold}) format('woff');
    font-style: normal;
    font-weight: 700;
  }
`;
